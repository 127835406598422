import { combineReducers } from 'redux';

// import flashMessages from './reducers/flashMessages';
import auth from './reducers/auth';
import userDetails from './reducers/userDetails';

export default combineReducers({
//   flashMessages,
  auth,
  userDetails
});