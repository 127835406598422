import React from 'react'; 
import './App.css'; 
import AppRoute from './AppRoute';
import Login from './Pages/Auth/Login';
import NotFound from './Pages/Errors/NotFound';
import Dashboard from './Pages/Dashboard/Dashboard';
import Attendance from './Pages/Attendance/Attendance';
import Examination from './Pages/Examination/Examination';
import OnlineAcademy from './Pages/OnlineAcademy/OnlineAcademy';
import OnlineTest from './Pages/OnlineTest/OnlineTest';
import OnlineTestAttend from './Pages/OnlineTest/OnlineTestAttend';
import Profile from './Pages/Profile/Profile';
import Subject from './Pages/Subject/Subject';
import Feedback from './Pages/Feedback/Feedback';
// import NewsFeed from './Pages/NewsFeed/NewsFeed';

import history from './utils/history';  
import {BrowserRouter as Router, Switch} from 'react-router-dom'; 
import requireAuth from './utils/requireAuth';
import DashboardLayout from './Layout/DashboardLayout';
import BlankLayout from './Layout/BlankLayout';

function App() {
  return (
    <Router  history={history}> 
      <div> 
        {/* <NavigationBar/> */}
        <Switch>
          <AppRoute path="/" exact component={requireAuth(Dashboard)} layout={DashboardLayout}/> 
          <AppRoute path="/dashboard" exact component={requireAuth(Dashboard)} layout={DashboardLayout}/> 
          <AppRoute path="/login" exact component={Login} layout={BlankLayout}/>  
          <AppRoute path="/attendance" exact component={requireAuth(Attendance)} layout={DashboardLayout}/> 
          <AppRoute path="/examination" exact component={requireAuth(Examination)} layout={DashboardLayout}/> 
          <AppRoute path="/onlineAcademy" exact component={requireAuth(OnlineAcademy)} layout={DashboardLayout}/> 
          <AppRoute path="/onlineTest" exact component={requireAuth(OnlineTest)} layout={DashboardLayout}/> 
          <AppRoute path="/onlineTest/:id" exact component={requireAuth(OnlineTestAttend)} layout={DashboardLayout}/> 
          <AppRoute path="/profile" exact component={requireAuth(Profile)} layout={DashboardLayout}/> 
          <AppRoute path="/subject" exact component={requireAuth(Subject)} layout={DashboardLayout}/>
          <AppRoute path="/feedback" exact component={requireAuth(Feedback)} layout={DashboardLayout}/> 
          <AppRoute path="*" exact component={NotFound} layout={BlankLayout}/>
        </Switch>
      </div>
	  </Router>
  );
}
 
export default App;
