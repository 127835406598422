import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import './Online.css';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps() {
  return ['Question 1', 'Question 2', 'Question 3']; 
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return `For each ad campaign that you create, you can control how much
              you're willing to spend on clicks and conversions, which networks
              and geographical locations you want your ads to show on, and more.`;
    case 1:
      return 'An ad group contains one or more ads which target a shared set of keywords.';
    case 2:
      return `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`;
    default:
      return 'Unknown step';
  }
}

export default function VerticalLinearStepper(questionIndex) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [open, setOpen] = React.useState(false);
//   const steps = getSteps();
  const steps = questionIndex.questionIndex;
  const question = questionIndex.questions;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  
  const handleFinalSub = () => {
    questionIndex.updateAnswer(0, 0, 1);
    handleClose();
    handleNext();
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel onClick={() => setActiveStep(index)} style={{cursor:'pointer',userSelect: 'none'}}>{label}</StepLabel>
            <StepContent>
              {/* <Typography>{getStepContent(index)}</Typography> */}
                {(question[index].question)? <Typography style={{userSelect: 'none'}} dangerouslySetInnerHTML={{ __html: question[index].question }}></Typography> : ""}
                {(question[index].image_path )? <Typography style={{userSelect: 'none'}}><img src={question[index].image_path } style={{maxWidth:"500px"}} /></Typography> : ""}
                <br/>
                <p>Select your answer</p>
                <hr style={{marginTop: '1rem',marginBottom: '0rem'}}/>
                <Typography style={{userSelect: 'none'}}>
                { question[index].options.map((opt, inx) =>(
                    <div key={inx} className="custom-control custom-radio mb-3">
                        <input name={question[index].question_id} value={opt.id} className="custom-control-input" 
                        onClick={ () => questionIndex.updateAnswer(question[index].question_id, opt.id, 0) } checked={(opt.marked_as_answer != 0)? "checked": null} id={"customRadio"+inx} type="radio" />
                        <label  className="custom-control-label" for={"customRadio"+inx} style={{height:'auto'}} >
                            {(opt.option)?<p style={{marginBottom:'0px'}} dangerouslySetInnerHTML={{ __html: opt.option }}></p>:""}
                            {(opt.image)?<img src={opt.image } style={{maxWidth:"300px"}} />:""}
                        </label>  
                  </div>
                ))}  
                </Typography> 
                <hr style={{marginTop: '1rem',marginBottom: '0rem'}}/>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Previous Question
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(activeStep === steps.length -1)? handleClickOpen :handleNext}
                    className={classes.button}
                  >
                   {activeStep === steps.length -1 ? 'Submit Answer' : 'Next Question'}
                  </Button> 
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>Exam completed - Thank You</Typography>
          {/* <Button onClick={handleReset} className={classes.button}>
            Reset
          </Button> */}
        </Paper>
      )}
      {/* Modal for final submit */}
        <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Click the Agree button to submit your answers or click the Disagree button to continue/review the exam
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Disagree
            </Button>
            <Button onClick={handleFinalSub} color="primary" autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
