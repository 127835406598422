import React from 'react';
// import { Link } from 'react-router-dom';
import SidebarNotification from '../../Components/Widget/SidebarNotification'

class Dashboard extends React.Component {
  render() {
    return (
      <>
        <div className="header bg-primary pb-6">
          <div className="container-fluid">
            <div className="header-body">
              <div className="row align-items-center py-4">
                {/* alert notification area */}
              </div>
            </div>
          </div>
        </div> 
        <div className="container-fluid mt--6">
        <div className="row">
            <div className="col-xl-4 col-md-6">
              <div className="row">
                <div className="col">
                  <div className="card card-stats"> 
                    <div className="card-body">
                      <div className="row">
                        <div className="col-6 mb-2 cursor-hover">
                          <div className="row">
                            <div className="col-8 pt-10per">
                              <h5 className="card-title text-uppercase text-muted mb-0">Calender</h5>   
                            </div>
                            <div className="col-4 pl-0 p-2">
                              <div className="icon2 icon-shape bg-gradient-orange text-white rounded-circle shadow">
                                <i className="ni ni-calendar-grid-58 ni-1rem"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 mb-2 cursor-hover">
                          <div className="row">
                            <div className="col-8 pt-10per">
                              <h5 className="card-title text-uppercase text-muted mb-0">Timetable</h5> 
                            </div>
                            <div className="col-4 pl-0 p-2">
                              <div className="icon2 icon-shape bg-gradient-green text-white rounded-circle shadow">
                                <i className="ni ni-collection ni-1rem"></i>
                              </div>
                            </div>  
                          </div>  
                        </div>  
                        <div className="col-6 mb-2 cursor-hover">
                          <div className="row">
                            <div className="col-8 pt-10per">
                              <h5 className="card-title text-uppercase text-muted mb-0">Subjects</h5> 
                            </div>
                            <div className="col-4 pl-0 p-2">
                              <div className="icon2 icon-shape bg-gradient-info text-white rounded-circle shadow">
                                <i className="ni ni-book-bookmark ni-1rem"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 mb-2 cursor-hover">
                          <div className="row">
                            <div className="col-8 pt-10per">
                              <h5 className="card-title text-uppercase text-muted mb-0">Exams</h5> 
                            </div>
                            <div className="col-4 pl-0 p-2">
                              <div className="icon2 icon-shape bg-gradient-info text-white rounded-circle shadow">
                                <i className="ni ni-single-copy-04 ni-1rem"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 
              </div> 
            </div>  
            
            <div className="col-xl-8 col-md-6">
              <div className="card card-stats"> 
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-0">Attendance Summary</h5>
                      {/* <span className="h2 font-weight-bold mb-0">100%</span> */}
                      <span className="h2 font-weight-bold mb-0">This feature Coming Soon</span>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow">
                        <i className="ni ni-chart-bar-32 ni-1rem"></i>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div> 
          <div className="row"> 
            <div className="col-xl-4 col-md-6">
              <SidebarNotification/>
            </div>
            <div className="col-xl-8 col-md-6"> 
              <div className="card card-stats"> 
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-0">News Feed</h5> 
                    </div> 
                  </div>
                </div>
              </div>
              <div className="text-center">
                <img className="w20" src={'assets/img/svg/notfound.svg'} alt="No Data Found" />
                <span className="display-block mt-15 font-size-medium font-weight-bold">Sorry No Feeds yet</span>
              </div>
            </div> 
          </div>
        </div>
      </>
    );
  }
}

export default Dashboard;
