import React from 'react';  
import { NavLink  } from 'react-router-dom';
import config from '../utils/config';

function SideBar() {
  return (
    <nav className="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white" id="sidenav-main">
      <div className="scrollbar-inner"> 
        <div className="sidenav-header d-flex align-items-center">
          <a className="navbar-brand" href="../../pages/dashboards/dashboard.html">
            <img src={config.imageUrl} className="navbar-brand-img" alt="..." />
            {/* <img src="../../assets/img/brand/blue.png" className="navbar-brand-img" alt="..." /> */}
          </a>
          <div className="ml-auto"> 
            <div className="sidenav-toggler d-none d-xl-block" data-action="sidenav-unpin" data-target="#sidenav-main">
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line"></i>
                <i className="sidenav-toggler-line"></i>
                <i className="sidenav-toggler-line"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-inner"> 
          <div className="collapse navbar-collapse" id="sidenav-collapse-main"> 
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink  className="nav-link" to="/dashboard" role="button">
                  <i className="ni ni-shop text-primary"></i>
                  <span className="nav-link-text">Dashboards</span>
                </NavLink > 
              </li>
              <li className="nav-item">
                <NavLink  className="nav-link" to="/attendance" role="button">
                  <i className="ni ni-ungroup text-orange"></i>
                  <span className="nav-link-text">Attendance</span>
                </NavLink > 
              </li>
              <li className="nav-item">
                <NavLink  className="nav-link" to="/subject" role="button">
                  <i className="ni ni-ui-04 text-info"></i>
                  <span className="nav-link-text">Subject</span>
                </NavLink > 
              </li>
              <li className="nav-item">
                <NavLink  className="nav-link" to="/examination" role="button">
                  <i className="ni ni-single-copy-04 text-pink"></i>
                  <span className="nav-link-text">Examination</span>
                </NavLink > 
              </li> 
              <li className="nav-item">
                <NavLink  className="nav-link" to="/feedback" role="button">
                  <i className="ni ni-single-copy-04 text-pink"></i>
                  <span className="nav-link-text">Feedback</span>
                </NavLink > 
              </li> 
              <li className="nav-item">
                <NavLink  className="nav-link" to="/onlineTest" role="button">
                  <i className="ni ni-single-copy-04 text-pink"></i>
                  <span className="nav-link-text">Online Exam</span>
                </NavLink > 
              </li> 
              <li className="nav-item">
                <NavLink  className="nav-link" to="/onlineAcademy" role="button">
                  <i className="ni ni-single-copy-04 text-pink"></i>
                  <span className="nav-link-text">Online Academy</span>
                </NavLink > 
              </li> 
            </ul>
        </div>
      </div>
        <div className="text-center copyRight">
          <h6 className="text-muted">Powered By</h6> 
          <h5 className="nav-link-text">Campus genie</h5>
        </div>
    </div>
  </nav>
  );
}

export default SideBar;
