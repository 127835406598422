import React from 'react';
// import React, { Component } from 'react';
import { Route } from 'react-router';
// import { rest } from 'lodash';

const AppRoute = ({component:Component, layout:Layout, ...rest}) =>( 
  <Route 
    {...rest}
    render={props => (
      <Layout>
        <Component {...props}/>
      </Layout>
    )}
  />
)

export default AppRoute;
