import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';


const TextFieldGroup = ({ field, value, label, icon, error, type, onChange, checkUserExists }) => {
    return (
        <div className={classnames('form-group', { 'has-error': error })}>
            <div className="input-group input-group-merge input-group-alternative">
                <div className="input-group-prepend">
                    <span className="input-group-text"><i className={icon}></i></span>
                </div>
                <input
                    onChange={onChange}
                    onBlur={checkUserExists}
                    className="form-control"
                    placeholder={label}
                    value={value}
                    type={type}
                    name={field}
                />
                <div>
                    {error && <span className="help-block">{error}</span>}
                </div>
            </div>
        </div>
    );
}


TextFieldGroup.propTypes = {
    field: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    error: PropTypes.string,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    checkUserExists: PropTypes.func
}

TextFieldGroup.defaultProps = {
    type: 'text'
}

export default TextFieldGroup;