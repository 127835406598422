import React from 'react';
import Breadcrumb from '../../Components/Common/Breadcrumbs'
import NoDataFound from '../../Components/Common/TableNoData';

class Subject extends React.Component {
  render() {
    return (
      <>
        <Breadcrumb header="Subject" breadcrumb="Subject" backButton={false} />
        <div className="container-fluid mt--6">
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-header">
                  <h5 className="h3 mb-0">Subject Lists</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="text-center w100">
                      <NoDataFound />
                    </div>
                    {/* <div className="col-md-3">
                      <div className="card bg-gradient-info border-0 cursor" data-toggle="tooltip" data-placement="left" title="Tooltip on left"> 
                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <h5 className="card-title text-uppercase text-muted mb-0 text-white">MAT</h5>
                              <span className="h2 font-weight-bold mb-0 text-white">ANE</span>
                            </div>
                            <div className="col-auto">
                              <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                                <i className="ni ni-single-copy-04"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>  */}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Subject;
