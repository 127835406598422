import React from 'react';

class TableNoData extends React.Component {
    render() {
        return <>
            <img className="w20" src={'assets/img/svg/notfound.svg'} alt="No Data Found" />
            <span className="display-block mt-15 font-size-medium font-weight-bold">Sorry No Data Found</span>
        </>;
    }
}

export default TableNoData;