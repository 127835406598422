import { SET_CURRENT_USER_DETAILS } from '../actions/types';
// import isEmpty from 'lodash/isEmpty';

const initialState = { 
  userDetails: {}
};

export default (state = initialState, action = {}) => {
  switch(action.type) {
    case SET_CURRENT_USER_DETAILS:
      return {
        userDetails: action.userDetails
      }; 
    default: return state;
  }
}
