import React from 'react';  
import validateInput from './LoginValidation';
import { connect } from 'react-redux';
import { login } from '../../actions/authActions';
import TextFieldGroup from '../../Components/Common/TextFieldGroup';
import PropTypes from 'prop-types'; 


class LoginForm extends React.Component { 
    constructor(props) {
        super(props);
        this.state = {
          username: '',
          password: '',
          errors: {},
          isLoading: false
        };
    
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    isValid() {
        const { errors, isValid } = validateInput(this.state);
    
        if (!isValid) {
          this.setState({ errors });
        }
    
        return isValid;
    }
   
    onSubmit(e) {
        e.preventDefault();
        if (this.isValid()) { 
          this.setState({ errors: {}, isLoading: true }); 
          this.props.login(this.state).then( 
            (res) => this.props.history.push('/dashboard'), 
            (err) => this.setState({ errors: {'cred':'Please Check Credentials and try again'}, isLoading: false })
            );   
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    render(){
        const { errors, username, password, isLoading } = this.state;
        return (
            <form onSubmit={this.onSubmit}>
                <TextFieldGroup
                    field="username"
                    icon="ni ni-email-83"
                    label="Username"
                    value={username}
                    error={errors.username}
                    onChange={this.onChange} 
                    />
                <TextFieldGroup
                    field="password"
                    icon="ni ni-lock-circle-open"
                    label="Password"
                    value={password}
                    error={errors.password}
                    onChange={this.onChange}
                    type="password"
                    />
                    {errors.cred && <span className="help-block">{errors.cred}</span>} 
                {/* <div className="form-group mb-3">
                    <div className="input-group input-group-merge input-group-alternative">
                        <div className="input-group-prepend">
                        <span className="input-group-text"><i className="ni ni-email-83"></i></span>
                        </div>
                        <input className="form-control" placeholder="Username" type="text" />
                    </div>
                </div>
                <div className="form-group">
                    <div className="input-group input-group-merge input-group-alternative">
                        <div className="input-group-prepend">
                        <span className="input-group-text"><i className="ni ni-lock-circle-open"></i></span>
                        </div>
                        <input className="form-control" placeholder="Password" type="password" />
                    </div>
                </div>  */}
                <div className="text-center">
                <button type="submit" className="btn btn-primary my-4" disabled={isLoading}>Sign in</button>
                </div>
            </form>
        );
    }
}

LoginForm.propTypes = {
    login: PropTypes.func.isRequired,
    history: PropTypes.object 
  }
  
  LoginForm.contextTypes = {
    router: PropTypes.object
  }

export default connect(null, { login }) (LoginForm);