import React from 'react';
// import Loader from '../../Components/Common/Loader';
import { Link } from 'react-router-dom';

class SidebarNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationList: [],
      isLoading: false
    }
  }
  componentDidMount() {}
  render(){
    return (
      <div className="card"> 
        <div className="card-header"> 
          <h5 className="h3 mb-0">Latest notifications</h5>
        </div> 
        <div className="card-body">
          <div className="timeline timeline-one-side" data-timeline-content="axis" data-timeline-axis-style="dashed">
            <div className="timeline-block">
              <span className="timeline-step badge-success">
                <i className="ni ni-bell-55"></i>
              </span>
              <div className="timeline-content">
                <div className="d-flex justify-content-between pt-1">
                  <div>
                    <span className="text-muted text-sm font-weight-bold">New message</span>
                  </div>
                  <div className="text-right">
                    <small className="text-muted"><i className="fas fa-clock mr-1"></i>2 hrs ago</small>
                  </div>
                </div>
                <h6 className="text-sm mt-1 mb-0">Hi, Please attend Online Examination by clicking below button.
                <br/>
                <br/>
                <Link to="/onlineTest" className="btn btn-primary">Go to Online Exam</Link>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
}

export default SidebarNotification