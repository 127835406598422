import React from 'react';
import NavigationBar from './NavigationBar';
import Sidebar from './SideBar';

export default ({children}) => (
    <>
        <Sidebar/>
        <div className="main-content" id="panel">
            <NavigationBar/>
            {children}
        </div> 
    </>
);