import React from 'react';
import NoDataFound from '../../Components/Common/TableNoData';
import Breadcrumb from '../../Components/Common/Breadcrumbs';

class Attendance extends React.Component {
  render() {
    return (
      <>
        <Breadcrumb header="Attendance" breadcrumb="Attendance" backButton={false} />
        <div className="container-fluid mt--6">
          <div className="row">

            <div className="col">
              <div className="card">
                <div className="card-header">
                  <h5 className="h3 mb-0">Attendance Summary</h5>
                </div>
                <div className="card-body">
                  <p className="card-text mb-4 text-center"><NoDataFound /></p>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h5 className="h3 mb-0">Attendance Between Two Dates</h5>
                </div>
                <div className="card-body">
                  <p className="card-text mb-4 text-center"><NoDataFound /></p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </>
    );
  }
}

export default Attendance;
