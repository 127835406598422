import React from 'react';  

class NotFound extends React.Component { 
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(e){
    this.props.history.push('/dashboard')
  }
  render(){
    return (
      <div id="blank-page" className="container-fluid">
        <div className="row justify-content-center">
            <div className="col-lg-8 card-wrapper mt-10">
              <div className="card">
                <div className="card-header text-center">
                  <h1 className="mb-0">404</h1>
                </div>
                <div className="card-body">
                  <div className="row ">
                    <div className="col-sm text-center">
                      <span>The page you are looking for does not exist.<br/>
                      Click the button below to go back to the homepage</span>
                      <br/>
                      <br/>
                      <button type="button" className="btn btn-primary" onClick={this.onClick}>Home</button>
                    </div> 
                  </div>
                </div>
              </div>
            </div>
        </div>
	   </div>
    );
  } 
}

export default NotFound;
