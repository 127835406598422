import React from 'react';
import './Online.css';
import axios from 'axios';
import { connect } from 'react-redux';
import { logout } from '../../actions/authActions';
import PropTypes, { array } from 'prop-types';
import Breadcrumb from '../../Components/Common/Breadcrumbs';
import base64 from 'react-native-base64';

import QuestionWizard from './questionWizard';
import Review from './review';

class OnlineTestAttend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subjectName: '',
      timeLeft: 'Nill',
      qPurl: '',
      errors: {},
      isLoading: false,
      instructionText: "",
      disableInstruction: false,
      disableQuestions: false,
      disableUpload: false,
      disableWidget: false,
      startExam: false,
      completed: false,
      examType: null,
      examEntryId: '',
      answerFile: '',
      questionIndex: {},
      questions: {},
      inputFileList: [0],
      fileList: []
    };

    this.enableInstruction = this.enableInstruction.bind(this);
    this.enableQuestions = this.enableQuestions.bind(this);
    this.enableUpload = this.enableUpload.bind(this);
    this.enableWidget = this.enableWidget.bind(this);
    this.enableStartExam = this.enableStartExam.bind(this); 
    this.submitExam = this.submitExam.bind(this);
    this.checkCompleted = this.checkCompleted.bind(this);
    this.updateAnswer = this.updateAnswer.bind(this);
    this.getQuestionPaper = this.getQuestionPaper.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.addInputFile = this.addInputFile.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.submitPaper = this.submitPaper.bind(this);
  }
  componentWillMount() {
    this.getQuestionPaper();
  }

  getQuestionPaper(){

    this.setState({ 'isLoading': true });
    return axios.get(`/online/examinations/${base64.decode((this.props.match.params.id).toString())}/question_paper`)
      .then(res => {
        if (res.data.question_paper != null) {
          this.setState({ 'isLoading': false });
          this.setState({
            'instructionText': res.data.exam_status.instructions,
            'examEntryId': res.data.exam_status.ex_entry_id,
            'subjectName': res.data.exam_status.subject_name,
            'completed': (res.data.exam_status.exam_completed_status === 0) ? false : true,
            'startExam': (res.data.exam_status.exam_attended_status === 0) ? false : true, 
            'examType': (res.data.exam_status.type) ? res.data.exam_status.type : null, 
          });
          if (this.state.completed === true) {
            this.checkCompleted();
          } else {
            if (this.state.startExam === true) {
              this.enableStartExam();
            } else {
              this.enableInstruction();
            }
          }
          if(this.state.examType === "OBJECTIVE"){
            let newQuestionIndex = [];
            let newQuestions = [];
            for(let i=0; i< res.data.question_paper.length; i++){ 
              newQuestionIndex.push('Question '+(i+1));
              newQuestions.push(res.data.question_paper[i]);
            }
            this.setState({'questionIndex': newQuestionIndex, questions:newQuestions});
          }else{
            this.setState({
              'qPurl': res.data.question_paper.question_paper,
              'fileList': (res.data.question_paper.files.length > 0) ? res.data.question_paper.files : []
            });
          } 
        } else {
          this.setState({ 'isLoading': false });
          alert("Something happened please try again");
        }
      }).catch(err => {
        if (err) {
          console.log(err);
          if (err.response) {
            console.log(err.response);
            if (err.response.status === 403) {
              this.props.logout();
            } else {
              this.setState({ 'isLoading': false });
              alert("Something happened please try again");
            }
          } else {
            this.setState({ 'isLoading': false });
            alert("Please check your internet connection and try again");
          }
        }
      });
  }

  enableInstruction() {
    this.setState({
      disableInstruction: true,
      disableQuestions: false
    });
  }
  enableQuestions() {
    this.setState({
      disableQuestions: true,
      disableInstruction: false,
      disableUpload: false
    });
  }
  enableUpload() {
    if(this.state.completed === false){
      this.setState({
        disableUpload: true,
        disableQuestions: false,
        disableInstruction: false,
      });
    }
  }
  enableWidget() {
    this.setState({
      disableWidget: true
    });
  }
  enableStartExam(e) {
    this.setState({
      disableInstruction: false,
      disableQuestions: true,
      startExam: true
    });
  }

  checkCompleted(e) {
    this.setState({
      disableInstruction: false,
      disableQuestions: true
    });
  }

  onFileChange(e) {
    this.setState({ answerFile: e.target.files })
  }

  addInputFile() {
    let arrayValue = this.state.inputFileList;
    arrayValue.push(arrayValue.length);
    this.setState({ inputFileList: arrayValue });
  }

  handleRemove(e) {
    let arrayValue = this.state.inputFileList;
    arrayValue.splice(e, 1);
    this.setState({ inputFileList: arrayValue });
  }

  updateAnswer(qus,op,final){
    this.setState({ 'isLoading': true });
    var formData = new FormData();
    if(final == 0)
    {
      formData.append('question_id', qus);
      formData.append('option_id', op);
    }
    formData.append('examEntryId', this.state.examEntryId);
    formData.append('finalSubmission', final);
    return axios.post(`/online/examinations/${base64.decode((this.props.match.params.id).toString())}/submit`, formData)
        .then(res => {
          if (res) {
            if (res.data.message === "Success") {
              this.setState({ 'isLoading': false });
            } else {
              // this.getQuestionPaper();
              if(final ==0){
                let updatedAnswer = this.state.questions.map(function(item){
                  if(item.question_id === qus){
                    item.options.map(function(item2){
                      if(item2.id === op){ 
                        item2.marked_as_answer = 1;
                      }else{
                        item2.marked_as_answer = 0;
                      }
                      return item2;
                    });
                  }
                  return item;
                }); 
                this.setState({ 'questions': updatedAnswer,'isLoading': false });
              }else{
                this.setState({ 'completed': true, 'isLoading': false });
                this.checkCompleted();
              }
              
            }
          }
        }).catch(err => {
          if (err) {
            if (err.response) {
              if (err.response.status === 500) {
                alert(err.response.data[0]);
                this.setState({ 'isLoading': false });
              } else {
                this.setState({ 'isLoading': false });
                alert("Something happened please try again");
              }
            } else {
              this.setState({ 'isLoading': false });
              alert("Please check your internet connection and try again");
            }
          }
        });
  }

  submitPaper(e) {
    this.setState({ 'isLoading': true });
    e.preventDefault();
    if (document.getElementById('answerFile').files.length > 0) {
      var formData = new FormData();
      formData.append('answerFile', this.state.answerFile[0]);
      formData.append('examEntryId', this.state.examEntryId);
      formData.append('finalSubmission', 0);
      return axios.post(`/online/examinations/${base64.decode(this.props.match.params.id)}/submit`, formData)
        .then(res => {
          if (res) {
            if (res.data.message === "Success") {
              let fileList = this.state.fileList;
              fileList.push(res.data.file_stored);
              this.setState({ 'isLoading': false, 'fileList': fileList});
              // console.log(this.state.fileList);
            } else {
              this.setState({ 'isLoading': false });
            }
          }
        }).catch(err => {
          if (err) {
            if (err.response) {
              if (err.response.status === 500) {
                alert(err.response.data[0]);
                this.setState({ 'isLoading': false });
              } else {
                this.setState({ 'isLoading': false });
                alert("Something happened please try again");
              }
            } else {
              this.setState({ 'isLoading': false });
              alert("Please check your internet connection and try again");
            }
          }
        });
    } else {
      alert("please select file for upload");
      this.setState({ 'isLoading': false });
    }
  }

  submitExam(e) {
    e.preventDefault();
    if (document.getElementById('noOfPaper').value > 0) {
      //check for uploaded file
      if (document.getElementById('noOfPaper').value == this.state.fileList.length) {
        var formData = new FormData();
        formData.append('noOfFile', document.getElementById('noOfPaper').value);
        formData.append('examEntryId', this.state.examEntryId);
        formData.append('finalSubmission', 1);
        this.setState({ 'isLoading': true });
        return axios.post(`/online/examinations/${base64.decode(this.props.match.params.id)}/submit`, formData)
          .then(res => {
            if (res) {
              this.setState({ 'completed': true, 'isLoading': false, 'disableUpload':false  });
              this.checkCompleted();
            }
          }).then(err => {
            if (err) {
              alert("Something happened please try again");
              this.setState({ 'completed': false, 'isLoading': false });
            }
          });
      } else {
        alert("Uploaded files and number of sheet added are not matching please check correctly");
      }
    } else {
      alert("Number of sheet should be greater than 0");
    } 
  }

  render() {
    var styleInstruction = {
      display: this.state.disableInstruction ? 'block' : 'none'
    };
    var styleQuestions = {
      display: this.state.disableQuestions ? 'block' : 'none'
    };  
    var styleUpload = {
      display: this.state.disableUpload ? 'block' : 'none'
    };
    var styleWidget = {
      display: this.state.disableWidget ? 'flex' : 'none'
    };
    var styleExam = {
      display: this.state.startExam ? 'none' : 'block'
    };
    var styleCompleted = {
      display: this.state.completed ? 'block' : 'none'
    };
    var styleLoading = {
      display: this.state.isLoading ? 'block' : 'none',
      left: this.state.isLoading ? '48.5%' : '0px'
    };

    const { instructionText, questionIndex, subjectName, questions, completed, qPurl, fileList, examType } = this.state; 
       
    return (
      <>
        <Breadcrumb header="Online Test" breadcrumb={"Online Test -"+ subjectName} backButton={true} />
        <div className="container-fluid mt--6">
          <div className="row text-center" style={{ position: 'fixed',zIndex: '9999',top: '45%',width: '79%'}}>
            <div className="loader-block" style={styleLoading}><div className="loader"></div></div>
          </div>
          <div className="row" style={styleCompleted}>
            <div className="col">
              <div className="card card-stats">
                <div className="card-body">
                  <div className="row">
                    <div className="col text-center pt-3 pb-3">
                      <span className="h2 font-weight-bold mb-0 text-uppercase">Thank you for Completing Exam</span>
                      <h5 className="card-title  text-muted mb-0">Faculty evaluating paper now. result will Publish soon.</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-3 col-md-6 cursorPointer" onClick={this.enableInstruction}>
              <div className="card card-stats">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-0">Instruction</h5>
                      <span className="h2 font-weight-bold mb-0">Read</span>
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                        <i className="fas fa-exclamation"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 cursorPointer" onClick={this.enableQuestions}>
              <div className="card card-stats">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-0">Question Paper</h5>
                      <span className="h2 font-weight-bold mb-0">Read</span>
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                        <i className="fas fa-question"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-xl-3 col-md-6"> */}
            <div className="col-xl-3 col-md-6 cursorPointer" onClick={this.enableUpload}>
              <div className="card card-stats">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-0">Answer Submit</h5>
                      <span className="h2 font-weight-bold mb-0">{(completed == true)? 'Submitted':'Pending'}</span>
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                        <i className="fas fa-cloud-upload-alt"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="card card-stats">
                <div className="card-body">
                  <div className="row">
                    <div className="col"> 
                      <h5 className="card-title text-uppercase text-muted mb-0">Exam End at</h5>
                      <span className="h2 font-weight-bold mb-0">--:--:--</span>
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow">
                        <i className="far fa-clock"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col" >
              <div className="card-wrapper">
                <div className="card" style={styleInstruction}>
                  <div className="card-header">
                    <h3 className="mb-0"><i className="fas fa-exclamation-circle"></i> Read the Instruction Carefully</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <div className="mb-0" dangerouslySetInnerHTML={{ __html: instructionText }}></div>
                      </div>
                    </div>
                    <div style={styleExam}>
                      <hr /> 
                      <div className="needs-validation"> 
                        <button className="btn btn-primary" onClick={this.enableStartExam}>Start Exam</button> 
                      </div>
                    </div>
                  </div>
                </div>
              {(examType == "OBJECTIVE")? 
                <div className="card" style={styleQuestions}>
                  <div className="card-header">
                    <div className="row">
                      <div className="col">
                        <h3 className="mb-0"><i className="fas fa-question-circle"></i> Question {(completed == true)? 'Answer - Review':'Paper'}</h3>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col"> 
                      {(completed == false) && 
                          (questionIndex.length > 0)? 
                          <QuestionWizard questionIndex={questionIndex} questions={questions} updateAnswer={this.updateAnswer}/>
                        : null
                      }
                      {(completed == true) && 
                          (questions.length > 0)?  
                          <Review  questionAnswer={this.state.questions} />
                        : null
                      }
                      </div>
                    </div>
                  </div>
                </div>
                 :     
                <div>
                  <div className="card" style={styleQuestions}>
                    <div className="card-header">
                      <div className="row">
                        <div className="col-8">
                          <h3 className="mb-0"><i className="fas fa-question-circle"></i> Question Paper </h3>
                        </div>
                        <div className="col-4 text-right"> 
                          <a href={qPurl} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-danger" download={subjectName}><span className="btn-inner--icon">Download <i className="ni ni-cloud-download-95"></i></span></a>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col">
                          <iframe src={qPurl} type="application/pdf" title="questions" id="pdfQustn"></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                 
                  <div className="card" style={styleUpload}>
                    <div className="card-header">
                      <h3 className="mb-0">Submit your Answer</h3>
                    </div>
                    <div className="card-body"> 
                      <div className="row">
                        <div className="col">
                          <div className="row">
                            <div className="col-8">
                              <h3>Upload your Papers (Single file)</h3>
                              <span className="text-danger">*Please upload Image file only</span>
                            </div>
                            <div className="col-4 text-right"></div>
                          </div>
                          <form onSubmit={this.submitPaper}>
                            <p className="mb-0 pt-4 displayFlex">
                              <input type="file" name="answerFile" id="answerFile" onChange={this.onFileChange} className="form-control col-md-3" />
                              <button className="btn btn-primary" type="submit">Upload</button> 
                            </p>
                          </form>
                          <p className="mb-0 pt-4">
                            {(fileList.length > 0) ? fileList.map((fileData, i) => {
                              return (
                                <span className="fileGroup" key={i}> <a href={fileData} target="_blank">Paper{i + 1} </a></span>
                              )
                            }) : "No file uploaded yet"} 
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="needs-validation">
                        <div className="form-group">
                          <label className="label" htmlFor="noOfPaper">Total Number of Paper</label>
                          <input type="number" id="noOfPaper" name="noOfPaper" className="form-control  col-md-1" />
                        </div> 
                        <button className="btn btn-primary" onClick={this.submitExam}>Final Submit</button> 
                      </div> 
                    </div>
                  </div>
                </div>
                }
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

OnlineTestAttend.propTypes = {
  logout: PropTypes.func.isRequired,
}

export default connect(null, { logout })(OnlineTestAttend);
