import React from 'react'; 

const review = ({ questionAnswer }) => {
    console.log(questionAnswer)
  return (
    <div> 
          {(questionAnswer.length > 0) && questionAnswer.map((qus, index) => {
            return (
               <div key={index}  style={{userSelect: 'none'}}>
                   <h3>Question {(index+1)} :</h3>
                   {(qus.question)? <p style={{userSelect: 'none'}} dangerouslySetInnerHTML={{ __html: qus.question }}></p>: null }
                   {(qus.image_path)? <img src={qus.image_path} style={{maxWidth:"500px",userSelect: 'none'}} />: null }
                   <h4>Your Answer :</h4>
                   { qus.options.map((opt, inx) =>{ return(
                    <div key={inx}>
                         {
                            (opt.marked_as_answer != 0)? 
                                ((opt.option)? <p style={{marginBottom:'0px'}} dangerouslySetInnerHTML={{ __html: opt.option }}></p> : null)
                            : null
                        }
                         {
                            (opt.marked_as_answer != 0)? 
                                ((opt.image)? <img src={opt.image } style={{maxWidth:"300px"}} />: null)
                            : null
                        }
                    </div> 
                    )})} 
                   <hr style={{marginTop: '1rem',marginBottom: '1rem'}}/>
               </div>
            )
          })} 
    </div>
  );
}

export default review