let url = 'https://akvb.campusgenie.co.in/api/v1/student';
let image = 'https://cg-student-portal.s3.ap-south-1.amazonaws.com/cg-logo.png';
let full = window.location.host
//window.location.host is subdomain.domain.com
let parts = full.split('.')
let sub = parts[0];
console.log(sub);
if(sub === 'student'){
    url = url;
    image = image;
}else if(sub === 'localhost:3000'){
    url = url;
    image = image;
}else{
    let urlTrim = sub.replace("-student", "");
    if(urlTrim != ""){ 
        url = 'https://'+urlTrim+'.campusgenie.co.in/api/v1/student';
        image = 'https://cg-student-portal.s3.ap-south-1.amazonaws.com/'+urlTrim+'-logo.png';
    }else{
        url = url;
        image = image;
    }
}

export default {
    apiUrl: url,
    imageUrl: image
}