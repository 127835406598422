import React from 'react';
import axios from 'axios';
import OnlineTestList from '../../Components/TableList/OnlineTestList';
import { connect } from 'react-redux';
import { logout } from '../../actions/authActions';
import PropTypes from 'prop-types'; 
import Loader from '../../Components/Common/Loader';
import Breadcrumb from '../../Components/Common/Breadcrumbs';

class OnlineTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      examList: [],
      isLoading: false
    }
  }
  componentDidMount() {  
      this.setState({'isLoading':true})
      return axios.get('/online/examinations/all').then(res => {     
        this.setState({ 'examList': res.data.examinations, 'isLoading':false }); 
      }).catch(err => {
        if(err){
          if(err.response){
            if(err.response.status === 403){
              this.props.logout();
            }else{
              this.setState({'isLoading':false});
              alert("Something happened please try again");
            }
          }else{
            this.setState({'isLoading':false});
            alert("Please check your internet connection and try again");
          }
        }
      }); 
  }
  render() {
    // const {examList} = this.state.examList;
    var styleLoading = {
      display:this.state.isLoading?'block':'none'
    };
    return (
      <>
      <Breadcrumb header="Online Test" breadcrumb="Online Test" backButton={false} />
        <div className="container-fluid mt--6">
          <div className="">
            <div className="card"> 
              <div className="card-header ">
                <div className="row">
                  <div className="col-6">
                    <h3 className="mb-0">Exam List</h3>
                  </div>
                  <div className="col-6 text-right"></div>
                </div>
              </div> 
              <div className="card-body p-0">
              {
                (!this.state.isLoading)? <OnlineTestList examList={this.state.examList}/> : <Loader/> 
              }
              </div>
            </div>
          </div>  
        </div> 
      </>
    );
  }
}

OnlineTest.propTypes = {
  logout: PropTypes.func.isRequired, 
}

export default connect(null, { logout }) (OnlineTest);
