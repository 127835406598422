import axios from 'axios';
import setAuthorizationToken from '../utils/setAuthorizationToken';
import jwtDecode from 'jwt-decode';
import { SET_CURRENT_USER, SET_CURRENT_USER_DETAILS } from './types';

export function setCurrentUser(user) {
    return {
        type: SET_CURRENT_USER,
        user
    };
}
export function setCurrentUserDetails(userDetails) {
    return {
        type: SET_CURRENT_USER_DETAILS,
        userDetails
    };
}

export function logout() {
    return dispatch => {
        return axios.get('/logout').then(res => {
            localStorage.removeItem('jwtToken');
            localStorage.removeItem('userDetails');
            setAuthorizationToken(false);
            dispatch(setCurrentUser({}));
            dispatch(setCurrentUserDetails({}));
        }).catch(err => {
            localStorage.removeItem('jwtToken');
            localStorage.removeItem('userDetails');
            setAuthorizationToken(false);
            dispatch(setCurrentUser({}));
            dispatch(setCurrentUserDetails({}));
        });
    }
}

export function login(data) {
    return dispatch => {
        return axios.post('/login', data).then(res => {
            const token = res.data.token.original.access_token;
            localStorage.setItem('jwtToken', token);
            localStorage.setItem('userDetails', JSON.stringify(res.data));
            setAuthorizationToken(token);
            dispatch(setCurrentUser(jwtDecode(token)));
            dispatch(setCurrentUserDetails(res.data));
        });
    }
}

