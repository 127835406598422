import React from 'react';
import { Link } from 'react-router-dom';
import TableNoData from '../Common/TableNoData';
import base64 from 'react-native-base64';
import moment from 'moment';

const OnlineTestList = ({ examList }) => {
  return (
    <div className="table-responsive">
      <table className="table align-items-center table-flush">
        <thead className="thead-light">
          <tr>
            <th>Subject</th>
            <th>Exam Date</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {(examList.length > 0) ? examList.map((exam, index) => {
            return (
              <tr key={index}>
                <td className="table-user">
                  {/* <img src="../../assets/img/theme/team-1.jpg" className="avatar rounded-circle mr-3" alt="" /> */}
                  <b>{exam.subject_name}</b>
                </td>
                <td>
                  <span className="text-muted">{ moment(exam.ex_date).format('D-MMM-YYYY')}</span>
                </td>
                <td>
                  <span className="text-muted">{exam.ex_start_time}</span>
                </td>
                <td>
                  <span className="text-muted">{exam.ex_end_time}</span>
                </td>
                <td className="table-actions">
                  {(exam.exam_completed_status === 0) ?
                    <Link to={`/onlineTest/${base64.encode((exam.exam_id).toString())}`} className="btn btn-info btn-sm">
                      <i className="fas fa-pencil-alt"></i> Attend
                                    </Link>
                    :
                    <button disabled className="btn btn-success btn-sm">
                      <i className="fas fa-check"></i> Already Attended
                                   </button>
                  }
                </td>
              </tr>
            )
          }) : <tr><td colSpan="5" className="text-center"><TableNoData /></td></tr>}

        </tbody>
      </table>
    </div>
  );
}

export default OnlineTestList