import React from 'react';
import NoDataFound from '../../Components/Common/TableNoData';
import Breadcrumb from '../../Components/Common/Breadcrumbs';

class Feedback extends React.Component {
  render() {
    return (
      <>
        <Breadcrumb header="Feedback" breadcrumb="Feedback" backButton={false} />
        <div className="container-fluid mt--6">
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-header">
                  <h5 className="h3 mb-0">Feedbacks</h5>
                </div>
                <div className="card-body">
                  <p className="card-text mb-4 text-center"><NoDataFound /></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Feedback;
