import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

const Breadcrumbs = ({ header, breadcrumb, backButton }) => {
  let history = useHistory();
  return (
    <div className="header bg-primary pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12 col-12">
              {backButton && <span className="h2 text-white d-inline-block mr-3 cursor" onClick={() => history.goBack()}><i className="fas fa-arrow-left"></i></span>}
              <h6 className="h2 text-white d-inline-block mb-0">{header}</h6>
              <nav aria-label="breadcrumb" className=" d-md-inline-block ml-md-4">
                <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                  <li className="breadcrumb-item"><Link to="/dashboard"><i className="fas fa-home"></i></Link></li>
                  <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">{breadcrumb}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


Breadcrumbs.propTypes = {
  header: PropTypes.string.isRequired,
  breadcrumb: PropTypes.string.isRequired,
  backButton: PropTypes.bool
}

Breadcrumbs.defaultProps = {
  type: 'text'
}

export default Breadcrumbs;