import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App'; 
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './rootReducer';
import setAuthorizationToken from './utils/setAuthorizationToken';
import jwtDecode from 'jwt-decode';
import { setCurrentUser, setCurrentUserDetails } from './actions/authActions';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import config from './utils/config';

axios.defaults.baseURL = config.apiUrl;
const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  )
);

if (localStorage.jwtToken) {
  setAuthorizationToken(localStorage.jwtToken);
  store.dispatch(setCurrentUser(jwtDecode(localStorage.jwtToken)));
  store.dispatch(setCurrentUserDetails(JSON.parse(localStorage.userDetails)));
}


ReactDOM.render(
  // <React.StrictMode> 
     <Provider store={store}>
      <App /> 
    </Provider>,
  //</React.StrictMode,
  document.getElementById('root')
);

 


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
